// Here you can add other styles

html {
    font-size: 1em !important;
}

// .c-sidebar-nav-link, .nav-item{
//     font-family: 'IndieFlower';
//     // font-weight: bold;
//     font-size: large;
// }
// .btn{
//     font-family: 'IndieFlower';
//     // font-weight: bold;
// }
// .modal-title{
//     font-family: 'IndieFlower';
//     // font-weight: bold;
//     font-size: large;
// }
// .c-footer{
//     font-family: 'IndieFlower';
//     // font-weight: bold;
//     font-size: large;
// }
